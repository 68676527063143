@font-face {
    font-family: 'Roboto-Regular';
    src:  local("Roboto-Regular"),
        url('./roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: local("Roboto-Medium")
        url("./roboto/Roboto-Medium.ttf") format('truetype');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: local("Roboto-Bold")
        url("./roboto/Roboto-Bold.ttf") format('truetype');
}