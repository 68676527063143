@import './fonts/font.css';



body {
  margin: 0;
  background-color: black;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Roboto-Regular', 'Roboto-Medium', 'Roboto-Bold',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  
}

/* .css-gdgs5w-MuiGrid-root-MainNav-mainContainer>.MuiGrid-item{
  overflow-y: auto !important;
} */

.css-1m4in8o-MuiGrid-root-AppBody-mainBodyContainer{
  overflow-y: auto !important;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
}


.css-11lq3yg-MuiGrid-root{
  flex-wrap: nowrap !important;
}
.MuiGrid-root .MuiGrid-container .css-11lq3yg-MuiGrid-root{
  flex-wrap: nowrap !important;
}

/* #root {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
 
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
 
} */

.tabbed-component-tab svg{
  width: 20px !important;
  height: 20px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Custom scrollbar styles for webkit-based browsers */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 8px;
  height: 5px;
}

/* Custom scrollbar styles for Firefox */
:vars{
  --primary-color : #D63F94
}

/* Firefox specific styles */
::-moz-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 20px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 20px;
}



